<template>
<div>
    <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1" id="page-header-text">
            {{ $store.state.userName 
                ? $t('accountPages.welcome', { name: $store.state.userName.split(' ')[0] }) 
                : $t('accountPages.header') }}
        </h1>
    </div>

    <div class="container py-3">
        <div class="row">
            <accountPageSidemenu />
            <div class="col-lg-9 col-md-8 col-sm-12" id="account-settings-tab">
                <div class="border my-2 rounded-2 shadow-sm">
                    <div class="container">
                        <div class="row p-3">
                            <ul class="nav nav-pills nav-fill gap-2 p-1 small bg-primary rounded-5 shadow-sm" id="settingsNav" role="tablist" style="--bs-nav-link-color: var(--bs-white); --bs-nav-pills-link-active-color: var(--bs-primary); --bs-nav-pills-link-active-bg: var(--bs-white);">
                                <div class="col">
                                    <li class="nav-item" role="presentation">
                                        <router-link to="/Account/Zerduz/Announcements" active-class="" class="nav-link text-white text-opacity-50 rounded-5" id="announcements-tab" type="button">{{ $t('accountPages.zerduzSettings.menu[1].title') }}</router-link>
                                    </li>
                                </div>
                                <div class="col">
                                    <li class="nav-item" role="presentation">
                                        <router-link to="/Account/Zerduz/Casting" active-class="" class="nav-link active rounded-5" id="casting-tab" type="button">{{ $t('accountPages.zerduzSettings.menu[2].title') }}</router-link>
                                    </li>
                                </div>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-casting" role="tabpanel" aria-labelledby="pills-casting" tabindex="0">
                                    <div class="row mt-4">
                                        <transition name="fade" mode="out-in">
                                            <div v-if="showTable" key="table" class="col-12">
                                            <h3 class="text-uppercase">Döküm</h3>
                                            <div class="table-responsive">
                                                    <table id="dokum-table" class="table mx-auto fw-bold text-center">
                                                        <thead>
                                                            <tr>
                                                                <th class="border border-end-0 border-3 rounded-start-2 bg-light text-primary fw-bold">ID</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Tarih</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Müşteri</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Toplam</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Lastik</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Taş</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Milyem</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Renk</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Mum</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Mamül</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Has</th>
                                                                <th class="border border-end-0 border-3 bg-light text-primary fw-bold">Katkı</th>
                                                                <th class="border border-3 rounded-end-2 bg-light text-primary fw-bold">İşlem</th>
                                                            </tr>
                                                        </thead>
                                                    <tbody class="align-middle">
                                                            <tr v-if="castingsLoading">
                                                                <td colspan="13" class="text-center py-4">
                                                                    <div class="d-flex flex-column align-items-center">
                                                                        <div class="spinner-border text-primary mb-2" role="status">
                                                                            <span class="visually-hidden">Yükleniyor...</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr v-else-if="items.length === 0">
                                                                <td colspan="13" class="text-center py-4">
                                                                    <div class="d-flex flex-column align-items-center">
                                                                        <span class="text-gray opacity-75">Döküm verisi yok</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr v-for="(item) in paginatedItems" :key="item.ID">
                                                                <td class="border border-end-0 border-3 rounded-start-2">{{ item.ID }}</td>
                                                                <td class="border border-end-0 border-3">{{ new Date(item.CreatedAt).toLocaleDateString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }) }}</td>
                                                                <td class="border border-end-0 border-3">{{ item.client_name }}</td>
                                                                <td class="border border-end-0 border-3">{{ item.total }}</td>
                                                                <td class="border border-end-0 border-3">{{ item.plastic }}</td>
                                                                <td class="border border-end-0 border-3">{{ item.stone }}</td>
                                                                <td class="border border-end-0 border-3">{{ item.ml }}</td>
                                                                <td class="border border-end-0 border-3">{{ item.color }}</td>
                                                                <td class="border border-end-0 border-3">{{ item.wax }}</td>
                                                                <td class="border border-end-0 border-3">{{ item.item_weight }}</td>
                                                                <td class="border border-end-0 border-3">{{ item.has.toFixed(3) }} GR</td>
                                                                <td class="border border-end-0 border-3">{{ item.alloy.toFixed(3) }} GR</td>
                                                                <td class="border border-3 rounded-end-2">
                                                                <button class="btn btn-light border-0 btn-sm fw-bold">GÖSTER</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                                <div v-if="items.length > 0" class="d-flex justify-content-end mt-3 mb-3">
                                                    <div class="btn-group btn-group-sm" role="group">
                                                        <button type="button" 
                                                                class="btn btn-outline-success d-flex align-items-center gap-1" 
                                                                @click="exportToExcel" 
                                                                :disabled="excelExportLoading">
                                                            <span v-if="excelExportLoading" class="spinner-border spinner-border-sm" role="status">
                                                                <span class="visually-hidden">Yükleniyor...</span>
                                                            </span>
                                                            <i v-else class="fa-solid fa-file-excel"></i>
                                                            <small>Excel</small>
                                                        </button>
                                                        <button type="button" 
                                                                class="btn btn-outline-danger d-flex align-items-center gap-1" 
                                                                @click="exportToPDF" 
                                                                :disabled="pdfExportLoading">
                                                            <span v-if="pdfExportLoading" class="spinner-border spinner-border-sm" role="status">
                                                                <span class="visually-hidden">Yükleniyor...</span>
                                                            </span>
                                                            <i v-else class="fa-solid fa-file-pdf"></i>
                                                            <small>PDF</small>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div v-else key="form" class="col-12">
                                                <h3>YENİ DERECE TANIMLA</h3>
                                                <form class="mt-3">
                                                    <div class="row g-1 mb-2">
                                                        <div class="col-6 col-sm-4 col-md mb-2 mb-sm-2 mb-md-0">
                                                            <input type="text" v-model="formData.toplam" class="form-control" placeholder="Toplam">
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md mb-2 mb-sm-2 mb-md-0">
                                                            <input type="text" v-model="formData.lastik" class="form-control" placeholder="Lastik">
                                                        </div>
                                                        <div class="col-6 col-sm-4 col-md mb-2 mb-sm-2 mb-md-0">
                                                            <input type="text" v-model="formData.tas" class="form-control" placeholder="Taş">
                                                        </div>
                                                        <div class="col-6 col-sm-6 col-md mb-2 mb-sm-2 mb-md-0">
                                                            <input type="text" v-model="formData.milyem" class="form-control" placeholder="Milyem">
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md mb-2 mb-sm-2 mb-md-0">
                                                            <select v-model="formData.renk" class="form-select" aria-label="Renk seçin">
                                                                <option value="">Renk seçin</option>
                                                                <option v-for="renk in renkler" :key="renk" :value="renk">{{ renk }}</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="row g-1 mb-2">
                                                        <div class="col-12">
                                                            <div class="position-relative" v-click-outside="closeCustomerDropdown">
                                                                <input type="text" 
                                                                    v-model="customerSearch" 
                                                                    class="form-control" 
                                                                    :placeholder="formData.musteriId ? `${formData.musteri} - ${customers.find(c => c.id === formData.musteriId)?.phone}` : 'Müşteri ara...'"
                                                                    @focus="onCustomerInputFocus">
                                                                
                                                                <div v-if="showCustomerDropdown" 
                                                                    class="position-absolute w-100 mt-1 bg-white border rounded-2 shadow-sm" 
                                                                    style="max-height: 200px; overflow-y: auto; z-index: 1000;">
                                                                    <div v-for="customer in filteredCustomers" 
                                                                        :key="customer.id" 
                                                                        class="p-2 cursor-pointer hover-bg-light"
                                                                        @click="selectCustomer(customer)"
                                                                        :class="{'bg-light': formData.musteriId === customer.id}">
                                                                        <div class="d-flex justify-content-between align-items-center">
                                                                            <span class="fw-bold">{{ customer.name }}</span>
                                                                            <span class="text-muted small">{{ customer.phone }}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="filteredCustomers.length === 0" class="p-2 text-muted text-center">
                                                                        Müşteri bulunamadı
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row g-1 mb-2 mt-2 mt-sm-2 mt-md-0">
                                                        <div class="col-12 col-sm-12 col-md-4 mb-2">
                                                            <div class="form-floating">
                                                                <input type="text" :value="mamulGr ? mamulGr.toFixed(3) : ''" class="form-control-plaintext" placeholder="Ürün Ağırlığı" readonly>
                                                                <label>Ürün Ağırlığı</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-12 col-md-4 mb-2">
                                                            <div class="form-floating">
                                                                <input type="text" :value="tavsiyeEdilenHas ? tavsiyeEdilenHas.toFixed(3) : ''" class="form-control-plaintext" placeholder="Tavsiye Edilen Has" readonly>
                                                                <label>Tavsiye Edilen Has</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-12 col-md-4">
                                                            <div class="form-floating">
                                                                <input type="text" :value="tavsiyeEdilenKatki ? tavsiyeEdilenKatki.toFixed(3) : ''" class="form-control-plaintext" placeholder="Tavsiye Edilen Katkı" readonly>
                                                                <label>Tavsiye Edilen Katkı</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="row g-1 mb-2 bg-secondary-subtle rounded-2 p-2">
                                                        <div class="col-6">
                                                            <div class="d-flex align-items-center">
                                                                <span class="me-2">Katkı:</span>
                                                                <span class="fw-bold">{{ tavsiyeEdilenKatki }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex align-items-center">
                                                                <span class="me-2">Has:</span>
                                                                <span class="fw-bold">{{ tavsiyeEdilenHas }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex align-items-center">
                                                                <span class="me-2">Mamul:</span>
                                                                <span class="fw-bold">{{ mamulGr }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex align-items-center">
                                                                <span class="me-2">Mum:</span>
                                                                <span class="fw-bold">{{ mumGr }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex align-items-center">
                                                                <span class="me-2">Ayar:</span>
                                                                <span class="fw-bold" :class="{'text-danger': !getAyarAndKatsayi.ayar}">
                                                                    {{ getAyarAndKatsayi.ayar ? getAyarAndKatsayi.ayar + 'K' : 'Geçersiz milyem' }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex align-items-center">
                                                                <span class="me-2">Katsayı:</span>
                                                                <span class="fw-bold" :class="{'text-danger': !getAyarAndKatsayi.katsayi}">
                                                                    {{ getAyarAndKatsayi || 'Geçersiz milyem' }}
                                                                </span>
                                                    </div>
                                                </div>
                                                        <div class="col-6">
                                                            <div class="d-flex align-items-center">
                                                                <span class="me-2">Müşteri:</span>
                                                                <span class="fw-bold">
                                                                    {{ formData.musteri ? formData.musteri + ' (' + formData.musteriId + ')' : 'Müşteri seçilmedi' }}
                                                                    <small v-if="formData.musteri" class="text-muted ms-1">
                                                                        ({{ customers.find(c => c.id === formData.musteriId)?.phone }})
                                                                    </small>
                                                                </span>
                                                    </div>
                                                </div>
                                                    </div> -->

                                                    <div class="mb-4">
                                                        <table class="table mx-auto fw-bold text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th class="border border-end-0 border-3 rounded-start-2 bg-light text-primary">Müşteri</th>
                                                                    <th class="border border-end-0 border-3 bg-light text-primary">Has</th>
                                                                    <th class="border border-end-0 border-3 bg-light text-primary">Milyem</th>
                                                                    <th class="border border-end-0 border-3 bg-light text-primary">Renk</th>
                                                                    <th class="border border-3 rounded-end-2 bg-light text-primary">Katkı</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="align-middle">
                                                                <tr class="min-h-50">
                                                                    <td class="border border-end-0 border-3 rounded-start-2">{{ formData.musteri }}</td>
                                                                    <td class="border border-end-0 border-3">{{ tavsiyeEdilenHas ? tavsiyeEdilenHas.toFixed(3) + ' GR' : '' }}</td>
                                                                    <td class="border border-end-0 border-3">{{ formData.milyem }}</td>
                                                                    <td class="border border-end-0 border-3">{{ formData.renk }}</td>
                                                                    <td class="border border-3 rounded-end-2">{{ tavsiyeEdilenKatki ? tavsiyeEdilenKatki.toFixed(3) + ' GR' : '' }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </form>
                                                    </div>
                                        </transition>

                                        <div class="col-12 mt-0 mt-sm-0 mt-md-3">
                                            <div class="row align-items-center">
                                                <div class="col mb-3 mb-sm-3 mb-md-3 mb-lg-au0">
                                                    <nav v-if="totalPages > 1 && showTable" aria-label="Döküm listesi sayfaları">
                                                        <ul class="pagination mb-0">
                                                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                                   href="#" 
                                                                   @click.prevent="currentPage--">
                                                                    <i class="fa-solid fa-chevron-left fa-xs"></i>
                                                                </a>
                                                            </li>
                                                            
                                                            <li v-if="currentPage > 2" class="page-item">
                                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                                   href="#" 
                                                                   @click.prevent="currentPage = 1">1</a>
                                                            </li>
                                                            
                                                            <li v-if="currentPage > 3" class="page-item disabled">
                                                                <span class="page-link border-0 bg-transparent text-secondary px-2">...</span>
                                                            </li>
                                                            
                                                            <li v-if="currentPage > 1" class="page-item">
                                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                                   href="#" 
                                                                   @click.prevent="currentPage--">
                                                                    {{ currentPage - 1 }}
                                                                </a>
                                                            </li>
                                                            
                                                            <li class="page-item active">
                                                                <a class="page-link rounded-2 border-0 px-2" 
                                                                   href="#" 
                                                                   @click.prevent>
                                                                    {{ currentPage }}
                                                                </a>
                                                            </li>
                                                            
                                                            <li v-if="currentPage < totalPages" class="page-item">
                                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                                   href="#" 
                                                                   @click.prevent="currentPage++">
                                                                    {{ currentPage + 1 }}
                                                                </a>
                                                            </li>
                                                            
                                                            <li v-if="currentPage < totalPages - 2" class="page-item disabled">
                                                                <span class="page-link border-0 bg-transparent text-secondary px-2">...</span>
                                                            </li>
                                                            
                                                            <li v-if="currentPage < totalPages - 1" class="page-item">
                                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                                   href="#" 
                                                                   @click.prevent="currentPage = totalPages">
                                                                    {{ totalPages }}
                                                                </a>
                                                            </li>
                                                            
                                                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                                   href="#" 
                                                                   @click.prevent="currentPage++">
                                                                    <i class="fa-solid fa-chevron-right fa-xs"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="row g-2">
                                                        <div class="col-auto">
                                                            <button type="button" class="btn custom-btn pink-btn d-flex flex-column align-items-center justify-content-center" :class="{ 'inactive': showTable }">
                                                            <span class="btn-text">S</span>
                                                            <small class="btn-label">SAT</small>
                                                        </button>
                                                    </div>
                                                        <div class="col-auto">
                                                            <button type="button" class="btn custom-btn pink-btn d-flex flex-column align-items-center justify-content-center" :class="{ 'inactive': showTable }">
                                                            <span class="btn-text">K</span>
                                                            <small class="btn-label">KATKI</small>
                                                        </button>
                                                    </div>
                                                        <div class="col-auto">
                                                            <button type="button" class="btn custom-btn pink-btn d-flex flex-column align-items-center justify-content-center" :class="{ 'inactive': showTable }">
                                                            <span class="btn-text">%</span>
                                                            <small class="btn-label">AYARLA</small>
                                                        </button>
                                                    </div>
                                                        <div class="col-auto">
                                                            <button type="button" @click="toggleView" class="btn custom-btn pink-btn d-flex flex-column align-items-center justify-content-center">
                                                                <i :class="showTable ? 'fas fa-plus' : 'fas fa-list'" class="btn-text"></i>
                                                                <small class="btn-label">{{ showTable ? 'EKLE' : 'LİSTE' }}</small>
                                                        </button>
                                                    </div>
                                                        <div class="col-auto">
                                                            <button type="button" @click="addNewCasting" class="btn custom-btn green-btn d-flex flex-column align-items-center justify-content-center" style="width: 100px;" :class="{ 'inactive': showTable }" :disabled="addingCasting">
                                                                <div v-if="addingCasting" class="spinner-border spinner-border-sm text-white" role="status">
                                                                    <span class="visually-hidden">Yükleniyor...</span>
                                                                </div>
                                                                <template v-else>
                                                            <span class="btn-text">OK</span>
                                                            <small class="btn-label">TAMAM</small>
                                                                </template>
                                                        </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import accountPageSidemenu from '@/components/accountPageSidemenu';
import { getCastings, addCasting, getCustomer } from "@/helpers/api";
import ExcelJS from 'exceljs';
import html2pdf from 'html2pdf.js';

export default {
    name: "AccountZerduzSettingsCasting",
    components: {
        accountPageSidemenu
    },
    directives: {
        'click-outside': {
            mounted(el, binding) {
                el.clickOutsideEvent = function(event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        binding.value(event);
                    }
                };
                document.addEventListener('click', el.clickOutsideEvent);
            },
            unmounted(el) {
                document.removeEventListener('click', el.clickOutsideEvent);
            }
        }
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            isActive: true,
            showTable: false,
            currentPage: 1,
            itemsPerPage: 20,
            items: [],
            castingsLoading: true,
            addingCasting: false,
            excelExportLoading: false,
            pdfExportLoading: false,
            customerSearch: '',
            showCustomerDropdown: false,
            customers: [],
            renkler: ['Siyah', 'Beyaz', 'Yeşil', 'Kırmızı'],
            formData: {
                toplam: '',
                lastik: '',
                tas: '',
                milyem: '',
                renk: '',
                musteri: '',
                musteriId: null,
                urunAgirligi: '',
            }
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        },
        startIndex() {
            return (this.currentPage - 1) * this.itemsPerPage;
        },
        paginatedItems() {
            const start = this.startIndex;
            const end = start + this.itemsPerPage;
            return this.items.slice(start, end);
        },
        getAyarAndKatsayi() {
            const milyem = parseFloat(this.formData.milyem) || 0;
            
            if (milyem <= 325) {
                return { ayar: 8, katsayi: 12 };
            } else if (milyem < 370) {
                return { ayar: 8, katsayi: 12 };
            } else if (milyem < 412) {
                return { ayar: 9, katsayi: 12 };
            } else if (milyem < 580) {
                return { ayar: 10, katsayi: 12 };
            } else if (milyem < 745) {
                return { ayar: 14, katsayi: 14 };
            } else if (milyem < 870) {
                return { ayar: 18, katsayi: 17 };
            } else if (milyem < 911) {
                return { ayar: 21, katsayi: 19 };
            } else if (milyem < 990) {
                return { ayar: 22, katsayi: 19 };
            } else {
                return { ayar: 24, katsayi: 19 };
            }
            
            // switch (milyem) {
            //     case milyem <= 325:
            //         return { ayar: 8, katsayi: 12 };
            //     case milyem > 325:
            //         return { ayar: 8, katsayi: 12 };
            //     case milyem >= 370:
            //         return { ayar: 9, katsayi: 12 };
            //     case milyem >= 412:
            //         return { ayar: 10, katsayi: 12 };
            //     case milyem >= 580:
            //         return { ayar: 14, katsayi: 14 };
            //     case milyem >= 745:
            //         return { ayar: 18, katsayi: 17 };
            //     case milyem >= 870:
            //         return { ayar: 21, katsayi: 19 };
            //     case milyem >= 911:
            //         return { ayar: 22, katsayi: 19 };
            //     case milyem >= 990:
            //         return { ayar: 24, katsayi: 19 };
            // }

        },
        mumGr() {
            const toplam = parseFloat(this.formData.toplam) || 0;
            const lastik = parseFloat(this.formData.lastik) || 0;
            const tas = parseFloat(this.formData.tas) || 0;
            
            const mumGr = toplam - (lastik + tas);
            return mumGr > 0 ? mumGr : 0;
        },
        mamulGr() {
            const mumGr = parseFloat(this.mumGr) || 0;
            const katsayi = this.getAyarAndKatsayi.katsayi;
            
            const mamulGr = mumGr * katsayi;
            return mamulGr > 0 ? mamulGr : 0;
        },
        tavsiyeEdilenHas() {
            const mamulGr = parseFloat(this.mamulGr) || 0;
            const milyem = parseFloat(this.formData.milyem) || 0;
            
            const hasGr = (mamulGr * milyem) / 1000;
            return hasGr;
        },
        tavsiyeEdilenKatki() {
            const mamulGr = parseFloat(this.mamulGr) || 0;
            const hasGr = parseFloat(this.tavsiyeEdilenHas) || 0;
            
            const katkiGr = mamulGr - hasGr;
            return katkiGr;
        },
        filteredCustomers() {
            if (!this.customerSearch) return this.customers;
            
            const search = this.customerSearch.toLowerCase();
            return this.customers.filter(customer => 
                customer.name.toLowerCase().includes(search) ||
                customer.phone.includes(search)
            );
        }
    },
    watch: {
        showTable(newVal) {
            if (newVal) {
                this.currentPage = 1;
                this.getCastingsList();
            }
        }
    },
    mounted() {
        if (this.showTable) {
            this.getCastingsList();
        }
        this.getCustomersList();
    },
    methods: {
        async getCustomersList() {
            try {
                const { response, success } = await getCustomer(this.token);
                if (success) {
                    this.customers = response.data.data.map(customer => ({
                        id: customer.data.ID,
                        name: customer.data.name,
                        phone: customer.data.phone
                    }));
                } else {
                    this.$showToast("Zerduz", "Müşteri listesi alınırken bir hata oluştu.", 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", "Müşteri listesi alınırken bir hata oluştu.", 5000);
            }
        },
        async getCastingsList() {
            try {
                this.castingsLoading = true;
                const { response, success } = await getCastings(this.token);

                if (success) {
                    this.items = response.data;
                } else {
                    this.$showToast("Zerduz", "Döküm listesi alınırken bir hata oluştu.", 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", "Döküm listesi alınırken bir hata oluştu.", 5000);
            } finally {
                this.castingsLoading = false;
            }
        },
        toggleView() {
            this.showTable = !this.showTable;
        },
        async addNewCasting() {
            if (!this.formData.musteri) {
                this.$showToast("Zerduz", "Lütfen müşteri adını giriniz.", 5000);
                return;
            }
            if (!this.formData.toplam) {
                this.$showToast("Zerduz", "Lütfen toplam değerini giriniz.", 5000);
                return;
            }
            if (!this.formData.milyem) {
                this.$showToast("Zerduz", "Lütfen milyem değerini giriniz.", 5000);
                return;
            }
            if (!this.formData.renk) {
                this.$showToast("Zerduz", "Lütfen renk seçiniz", 5000);
                return;
            }

            try {
                this.addingCasting = true;
                const castingData = {
                    total: parseFloat(this.formData.toplam.toString().replace(',', '.')) || 0,
                    wax: parseFloat(this.mumGr.toString().replace(',', '.')) || 0,
                    plastic: parseFloat(this.formData.lastik.toString().replace(',', '.')) || 0,
                    stone: parseFloat(this.formData.tas.toString().replace(',', '.')) || 0,
                    ml: parseInt(this.formData.milyem) || 0,
                    color: this.formData.renk,
                    client_id: this.formData.musteriId,
                    client_name: this.formData.musteri,
                    item_weight: parseFloat(this.mamulGr.toString().replace(',', '.')) || 0,
                    has: parseFloat(this.tavsiyeEdilenHas.toString().replace(',', '.')) || 0,
                    alloy: parseFloat(this.tavsiyeEdilenKatki.toString().replace(',', '.')) || 0
                };

                const { success } = await addCasting(this.token, castingData);

                if (success) {
                    this.$showToast("Zerduz", "Döküm başarıyla eklendi.", 5000);
                    this.resetForm();
                    this.showTable = true;
                } else {
                    this.$showToast("Zerduz", "Döküm eklenirken bir hata oluştu.", 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", "Döküm eklenirken bir hata oluştu.", 5000);
            } finally {
                this.addingCasting = false;
            }
        },
        resetForm() {
            if (!this.showTable) {
                this.formData = {
                    toplam: '',
                    lastik: '',
                    tas: '',
                    milyem: '',
                    renk: '',
                    musteri: '',
                    musteriId: null,
                    urunAgirligi: '',
                };
            }
        },
        selectCustomer(customer) {
            this.formData.musteri = customer.name;
            this.formData.musteriId = customer.id;
            this.customerSearch = '';
            this.showCustomerDropdown = false;
        },
        closeCustomerDropdown() {
            this.showCustomerDropdown = false;
        },
        onCustomerInputFocus() {
            this.showCustomerDropdown = true;
        },
        async exportToExcel() {
            try {
                this.excelExportLoading = true;
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Döküm');

                const headerStyle = {
                    font: { bold: true, color: { argb: 'FFFFFF' } },
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '2B5BA1' } },
                    alignment: { horizontal: 'left' }
                };

                worksheet.columns = [
                    { header: 'ID', key: 'id', width: 10 },
                    { header: 'Tarih', key: 'date', width: 20 },
                    { header: 'Müşteri', key: 'client_name', width: 30 },
                    { header: 'Toplam', key: 'total', width: 15 },
                    { header: 'Lastik', key: 'plastic', width: 15 },
                    { header: 'Taş', key: 'stone', width: 15 },
                    { header: 'Milyem', key: 'ml', width: 15 },
                    { header: 'Renk', key: 'color', width: 15 },
                    { header: 'Mum', key: 'wax', width: 15 },
                    { header: 'Mamül', key: 'item_weight', width: 15 },
                    { header: 'Has', key: 'has', width: 15 },
                    { header: 'Katkı', key: 'alloy', width: 15 }
                ];

                worksheet.getRow(1).eachCell((cell) => {
                    cell.style = headerStyle;
                });

                this.items.forEach(item => {
                    worksheet.addRow({
                        id: item.ID,
                        date: new Date(item.CreatedAt).toLocaleDateString('tr-TR', { 
                            day: '2-digit', 
                            month: '2-digit', 
                            year: 'numeric', 
                            hour: '2-digit', 
                            minute: '2-digit' 
                        }),
                        client_name: item.client_name,
                        total: item.total,
                        plastic: item.plastic,
                        stone: item.stone,
                        ml: item.ml,
                        color: item.color,
                        wax: item.wax,
                        item_weight: item.item_weight,
                        has: `${item.has.toFixed(3)} GR`,
                        alloy: `${item.alloy.toFixed(3)} GR`
                    });
                });

                const buffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/octet-stream' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'dokum_listesi.xlsx';
                link.click();
                URL.revokeObjectURL(link.href);

                this.$showToast("Zerduz", "Döküm listesi Excel olarak dışarı aktarıldı.", 5000);
            } catch (error) {
                this.$showToast("Zerduz", "Döküm listesi Excel olarak dışarı aktarılırken bir hata oluştu.", 5000);
            } finally {
                this.excelExportLoading = false;
            }
        },
        async exportToPDF() {
            try {
                this.pdfExportLoading = true;
                const content = `
                    <div style="padding: 20px;">
                        <h2 style="color: #2B5BA1; text-align: center; margin-bottom: 30px;">Döküm&nbsp;&nbsp;Listesi</h2>
                        
                        <table style="width: 100%; border-collapse: collapse;">
                            <thead>
                                <tr style="background: #2B5BA1; color: white;">
                                    <th style="padding: 12px; text-align: left;">ID</th>
                                    <th style="padding: 12px; text-align: left;">Tarih</th>
                                    <th style="padding: 12px; text-align: left;">Müşteri</th>
                                    <th style="padding: 12px; text-align: left;">Toplam</th>
                                    <th style="padding: 12px; text-align: left;">Lastik</th>
                                    <th style="padding: 12px; text-align: left;">Taş</th>
                                    <th style="padding: 12px; text-align: left;">Milyem</th>
                                    <th style="padding: 12px; text-align: left;">Renk</th>
                                    <th style="padding: 12px; text-align: left;">Mum</th>
                                    <th style="padding: 12px; text-align: left;">Mamül</th>
                                    <th style="padding: 12px; text-align: left;">Has</th>
                                    <th style="padding: 12px; text-align: left;">Katkı</th>
                                </tr>
                            </thead>    
                            <tbody>
                                ${this.items.map(item => `
                                    <tr style="border-bottom: 1px solid #eee;">
                                        <td style="padding: 8px;">${item.ID}</td>
                                        <td style="padding: 8px;">${new Date(item.CreatedAt).toLocaleDateString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
                                        <td style="padding: 8px;">${item.client_name}</td>
                                        <td style="padding: 8px;">${item.total}</td>
                                        <td style="padding: 8px;">${item.plastic}</td>
                                        <td style="padding: 8px;">${item.stone}</td>
                                        <td style="padding: 8px;">${item.ml}</td>
                                        <td style="padding: 8px;">${item.color}</td>
                                        <td style="padding: 8px;">${item.wax}</td>
                                        <td style="padding: 8px;">${item.item_weight}</td>
                                        <td style="padding: 8px;">${item.has.toFixed(3)} GR</td>
                                        <td style="padding: 8px;">${item.alloy.toFixed(3)} GR</td>
                                    </tr>
                                `).join('')}
                            </tbody>
                        </table>
                    </div>
                `;

                const opt = {
                    margin: 1,
                    filename: 'dokum_listesi.pdf',
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
                };

                const element = document.createElement('div');
                element.innerHTML = content;
                await html2pdf().set(opt).from(element).save();

                this.$showToast("Zerduz", "Döküm listesi PDF olarak dışarı aktarıldı.", 5000);
            } catch (error) {
                this.$showToast("Zerduz", "Döküm listesi PDF olarak dışarı aktarılırken bir hata oluştu.", 5000);
            } finally {
                this.pdfExportLoading = false;
            }
        }
    }
};
</script>

<style scoped src="../assets/css/account.css"/>

<style scoped>
.small-input {
    font-size: 12px;
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
}

.table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
}

.min-h-50 td {
    min-height: 50px;
    height: 50px;
}

.custom-btn {
    width: 60px;
    height: 60px;
    border-radius: 12px;
    border: none;
    padding: 8px;
}

.custom-btn:not(.inactive) {
    transition: all 0.025s ease-out;
}

.btn-text {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
}

.btn-label {
    font-size: 10px;
    margin-top: 4px;
}

.pink-btn {
    background-color: #F406FA;
    color: white;
}

.pink-btn.inactive {
    background-color: #370739;
    cursor: not-allowed;
    pointer-events: auto;
}

.pink-btn.inactive * {
    pointer-events: none;
}

.green-btn {
    background-color: #0CEE61;
    color: white;
}

.green-btn.inactive {
    background-color: #370739;
    cursor: not-allowed;
    pointer-events: auto;
}

.green-btn.inactive * {
    pointer-events: none;
}

.custom-btn:not(.inactive):hover {
    opacity: 0.9;
    transform: scale(0.98);
}

.preview-row {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@media (max-width: 576px) {
    .table {
        font-size: 14px;
    }
    
    .custom-btn {
        width: 50px;
        height: 50px;
    }

    .btn-text {
        font-size: 20px;
    }

    .btn-label {
        font-size: 9px;
    }
}

.pagination {
    gap: 4px;
}

.page-link {
    color: #666;
    background-color: transparent;
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    margin: 0 2px;
    transition: all 0.2s ease;
    border: none;
}

.page-link:hover:not(.active):not(:disabled) {
    background-color: #f0f0f0;
    color: var(--bs-primary);
}

.page-item.active .page-link {
    background-color: var(--bs-primary);
    color: white;
}

.page-item.disabled .page-link {
    background-color: transparent;
    opacity: 0.5;
    cursor: not-allowed;
}

.page-link:focus {
    box-shadow: none;
}

.cursor-pointer {
    cursor: pointer;
}

.hover-bg-light:hover {
    background-color: var(--bs-light);
}

/* Müşteri dropdown scroll bar stilleri */
.position-absolute::-webkit-scrollbar {
    width: 8px;
}

.position-absolute::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.position-absolute::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.position-absolute::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.table td, .table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: auto; 
}

.table-responsive {
    overflow-x: auto;
}
</style>
